import React from 'react';

import { Alert, AlertIcon, AlertTitle, AlertDescription, Link } from '@chakra-ui/react';
import { useGetUserSelfQuery } from 'services/api/user';
import Button from 'components/common/Button';
import { getDateTime } from 'utils/dateTime';

import { STRIPE_BUTTON_LINK } from 'utils/consts';

const SubcribeAlert = () => {
  const userSelfQuery = useGetUserSelfQuery();

  if (STRIPE_BUTTON_LINK && userSelfQuery?.isSuccess && userSelfQuery.data.plan_tier === 'free') {
    const { plan_expiry } = userSelfQuery.data;
    return (
      <Alert status="warning" borderRadius="lg" marginBottom={4}>
        <AlertIcon />
        <AlertTitle>Your subscription is about to expire at {getDateTime(plan_expiry.toString(), '-')}!</AlertTitle>
        <AlertDescription>Please upgrade your plan to continue using the app.</AlertDescription>
        <Link href={STRIPE_BUTTON_LINK}>
          <Button colorScheme="blue" size="sm" ml={2}>
            Upgrade Plan
          </Button>
        </Link>
      </Alert>
    );
  }

  return null;
};

export default SubcribeAlert;
